<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogColumn"
      scrollable
      @click:outside="closePop"
      max-width="500px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Select The Column To Be Displayed</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="closePop"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2" v-if="listColumn">
          <v-container>
            <section class="px-2 my-3 d-flex justify-space-between">
              <v-checkbox
                dense
                hide-details
                @click="selectAll"
                v-model="isSelectAll"
                :value="true"
                color="success"
                label="Select All"
                class="ma-0"
              ></v-checkbox>
              <div v-if="needReset">
                <v-tooltip top color="green" max-width="200px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      depressed
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="resetCol"
                      color="green"
                      dark
                      ><v-icon small>mdi-backup-restore</v-icon></v-btn
                    >
                  </template>
                  <span>Reset Column</span>
                </v-tooltip>
              </div>
            </section>
            <v-card
              v-for="(column, idx) in listColumn"
              :key="idx"
              class="pa-2 mb-3"
            >
              <div v-if="from == 'parent'">
                <v-checkbox
                  v-model="selectedColumn"
                  :label="column.text"
                  :value="column"
                  color="success"
                  dense
                  class="mb-1"
                  hide-details="true"
                ></v-checkbox>
              </div>
              <div v-if="from == 'child'">
                <v-checkbox
                  v-model="exportColumn"
                  :label="column.text"
                  :value="column"
                  color="success"
                  dense
                  class="mb-1"
                  hide-details="true"
                ></v-checkbox>
              </div>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" dark depressed @click="pickColumn">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "dialogColumn",

  data() {
    return {
      loading: false,
      isSelectAll: null,
      exportColumn: [
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "nama_lengkap",
          cols: "nama_lengkap",
        },
        {
          text: "EMAIL",
          align: "start",
          sortable: false,
          value: "email",
          cols: "email",
        },
        {
          text: "UNIVERSITY",
          align: "start",
          sortable: false,
          value: "relationships[0].scholar.universitas",
          cols: "universitas",
        },
        { text: "STATUS", sortable: false, value: "status", cols: "status" },
      ],
      selectedColumn: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          cols: "id",
        },
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "nama_lengkap",
          cols: "nama_lengkap",
        },
        {
          text: "EMAIL",
          align: "start",
          sortable: false,
          value: "email",
          cols: "email",
        },
        {
          text: "UNIVERSITY",
          align: "start",
          sortable: false,
          value: "relationships[0].scholar.universitas",
          cols: "universitas",
        },
        { text: "STATUS", sortable: false, value: "status", cols: "status" },
        { text: "ROLE", sortable: false, value: "role", cols: "role" },
        { text: "REPORT", sortable: false, value: "report", cols: "report" },
        { text: "RECAP", sortable: false, value: "recap", cols: "recap" },
        { text: "ACTION", sortable: false, value: "actions", cols: "action" },
      ],
      needReset: false,
    };
  },
  props: ["dialogColumn", "listColumn", "from"],
  mounted() {},
  methods: {
    closePop() {
      this.$emit("close");
      this.resetCol();
    },
    resetCol() {
      this.isSelectAll = null;
      this.needReset = false;

      if (this.from == "parent") {
        this.selectedColumn = [
          {
            text: "FULL NAME",
            align: "start",
            sortable: false,
            value: "nama_lengkap",
            cols: "nama_lengkap",
          },
          {
            text: "EMAIL",
            align: "start",
            sortable: false,
            value: "email",
            cols: "email",
          },
          {
            text: "UNIVERSITY",
            align: "start",
            sortable: false,
            value: "relationships[0].scholar.universitas",
            cols: "universitas",
          },
        ];
      } else {
        this.exportColumn = [
          {
            text: "ID",
            align: "start",
            sortable: false,
            value: "id",
            cols: "id",
          },
          {
            text: "FULL NAME",
            align: "start",
            sortable: false,
            value: "nama_lengkap",
            cols: "nama_lengkap",
          },
          {
            text: "EMAIL",
            align: "start",
            sortable: false,
            value: "email",
            cols: "email",
          },
          {
            text: "UNIVERSITY",
            align: "start",
            sortable: false,
            value: "relationships[0].scholar.universitas",
            cols: "universitas",
          },
          { text: "STATUS", sortable: false, value: "status", cols: "status" },
          { text: "ROLE", sortable: false, value: "role", cols: "role" },
          { text: "REPORT", sortable: false, value: "report", cols: "report" },
          { text: "RECAP", sortable: false, value: "recap", cols: "recap" },
          { text: "ACTION", sortable: false, value: "actions", cols: "action" },
        ];
      }
    },
    selectAll() {
      this.needReset = true;

      if (this.isSelectAll) {
        let data = this.listColumn.map((x) => {
          return x;
        });

        if (this.from == "parent") {
          this.selectedColumn = data;
        } else {
          this.exportColumn = data;
        }

        this.selectedItem = data;
      } else {
        if (this.from == "parent") {
          this.selectedColumn = [];
        } else {
          this.exportColumn = [];
        }
      }
    },
    pickColumn() {
      let column =
        this.from == "parent" ? this.selectedColumn : this.exportColumn;
      this.$emit("pick", column, this.from);
    },
  },
};
</script>

<style scoped>
.v-dialog {
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
}
</style>
