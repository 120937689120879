<template>
  <v-row justify="start">
    <v-dialog v-model="dialogDelete" persistent max-width="400px">
      <v-card class="rounded-max" v-if="dataItem">
        <v-toolbar dense flat>
          <!-- <h2>Move Report To trash</h2> -->
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-container>
          <div class="d-flex flex-column align-center" v-if="dataItem.length">
            <img
              src="../../../assets/img/delete-icon.png"
              height="150px"
              alt=""
            />
            <div>
              <h2 class="ma-0 text-center">Are you sure?</h2>
              <p class="ma-0 text-center">
                {{ dataItem.length }} items will be moved to trash
              </p>
            </div>
          </div>
          <div
            v-if="!dataItem.length"
            class="mb-3 d-flex justify-center flex-column align-center"
          >
            <img
              src="../../../assets/img/V.1 250821 Icon Select Item Deleted svg0.svg"
              height="200px"
              alt=""
            />
            <h3 class="text-center mt-3">Please select the item to be deleted</h3>
          </div>
        </v-container>
        <v-card-actions class="" v-if="dataItem.length">
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            class="text-capitalize"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="!loading"
            color="red"
            class="text-capitalize"
            depressed
            dark
            @click="deleteItem"
            ><v-icon class="mr-2">mdi-delete</v-icon>
            <p class="ma-0">move to trash</p></v-btn
          >
          <v-btn
            v-if="loading"
            color="red"
            class="text-capitalize"
            dark
            depressed
            ><v-progress-circular
              :size="25"
              class="mr-2"
              indeterminate
            ></v-progress-circular>
            Loading</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "deleteReport",
  data() {
    return {
      loading: false,
    };
  },
  props: ["dialogDelete", "dataItem", "role"],

  methods: {
    deleteItem() {
      this.loading = true;
      let body = {
        id: this.dataItem,
      };
      let data = {
        body: body,
        type: "user",
      };
      this.$store
        .dispatch("trash/moveToTrash", data)
        .then((data) => {
          if (data.status == "success") {
            this.$emit("after");
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
        });
    },
  },
};
</script>

<style></style>
