<template>
  <div class="pa-3">
    <v-toolbar dense flat class="mb-5" color="#EDF1F0">
      <h2 class="text-capitalize">{{ label }} List</h2>
      <v-spacer></v-spacer>
      <div v-if="access">
        <v-btn
          v-if="access[route].length > 1"
          class="text-capitalize mr-3"
          color="green"
          dark
          :to="`/create/user/${route}`"
        >
          <v-icon>mdi-plus</v-icon> New {{ label }}</v-btn
        >
        <v-btn
          v-if="$route.params.role == 'scholar'"
          class="text-capitalize"
          color="green"
          dark
          to="/batch/user/scholar"
        >
          <v-icon>mdi-plus-box-multiple</v-icon> Automatic Input</v-btn
        >
      </div>
      <div v-if="role == 'SUPERADMIN'">
        <v-btn
          class="text-capitalize mr-3"
          color="green"
          dark
          :to="`/create/user/${route}`"
        >
          <v-icon>mdi-plus</v-icon> New {{ label }}</v-btn
        >
        <v-btn
          v-if="$route.params.role == 'scholar'"
          class="text-capitalize"
          color="green"
          dark
          to="/batch/user/scholar"
        >
          <v-icon>mdi-plus-box-multiple</v-icon> Automatic Input</v-btn
        >
      </div>
    </v-toolbar>
    <!-- TOOLBAR SCHOLAR -->
    <v-card class="rounded-max" style="overflow: hidden" flat>
      <div class="pa-3">
        <v-card
          flat
          color="#EDF1F0"
          class="rounded-lg pa-3"
          v-if="$route.params.role == 'scholar'"
        >
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                solo
                dense
                type="search"
                autocomplete="off"
                label="Search "
                prepend-inner-icon="mdi-magnify"
                hide-details
                flat
                v-model="find"
                @keydown="getItem()"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="2">
              <v-select
                dense
                hide-details
                :items="getYear"
                item-text="state"
                item-value="abbr"
                v-model="filterYear"
                @change="getItem()"
                label="Filter Year"
                flat
                solo
              ></v-select>
            </v-col>
            <v-col cols="6" md="2">
              <v-btn
                block
                color="white"
                depressed
                class="text-capitalize"
                height="100%"
                @click="selectColumns('parent')"
                ><v-icon color="orange" class="mr-2">mdi-filter-variant</v-icon>
                Filter Column</v-btn
              >
            </v-col>
            <v-col cols="6" md="2" v-if="!bulkDel">
              <v-btn
                blocko
                color="white"
                depressed
                block
                class="text-capitalize"
                height="100%"
                @click="dialogExport = true"
                ><v-icon color="green" class="mr-2">mdi-microsoft-excel</v-icon>
                Export Data</v-btn
              >
            </v-col>
            <v-col cols="6" md="2" v-if="bulkDel">
              <v-btn
                block
                dark
                color="red"
                depressed
                class="text-capitalize"
                height="100%"
                @click="deleteBulk"
                ><v-icon class="mr-1">mdi-delete</v-icon> Delete</v-btn
              >
            </v-col>
            <v-col cols="6" md="1">
              <v-btn
                v-if="!bulkDel"
                color="white"
                depressed
                class="text-capitalize"
                @click="bulkDel = true"
                ><v-icon color="red">mdi-delete</v-icon>
              </v-btn>
              <v-btn
                v-if="bulkDel"
                color="white"
                depressed
                class="text-capitalize"
                @click="bulkDel = false"
                ><v-icon color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <!-- TOOLBAR ADMIN -->
        <v-card
          flat
          color="#EDF1F0"
          class="rounded-lg pa-3"
          v-if="$route.params.role !== 'scholar'"
        >
          <v-row>
            <v-col
              :cols="role == 'SUPERADMIN ' || role == 'DATA_CONTROL' ? 9 : 10"
            >
              <v-text-field
                solo
                dense
                type="search"
                autocomplete="off"
                label="Search "
                prepend-inner-icon="mdi-magnify"
                hide-details
                flat
                v-model="find"
                @keydown="getItem()"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2" v-if="!bulkDel">
              <v-btn
                blocko
                color="white"
                depressed
                block
                class="text-capitalize"
                height="100%"
                @click="showDialogExportAdmin"
                ><v-icon color="green" class="mr-2">mdi-microsoft-excel</v-icon>
                Export Data</v-btn
              >
            </v-col>
            <v-col cols="6" md="2" v-if="bulkDel">
              <v-btn
                block
                dark
                color="red"
                depressed
                class="text-capitalize"
                height="100%"
                @click="deleteBulk"
                ><v-icon class="mr-1">mdi-delete</v-icon> Delete</v-btn
              >
            </v-col>
            <v-col
              cols="6"
              md="1"
              v-if="role == 'SUPERADMIN ' || role == 'DATA_CONTROL'"
            >
              <v-btn
                v-if="!bulkDel"
                color="white"
                depressed
                class="text-capitalize"
                @click="bulkDel = true"
                ><v-icon color="red">mdi-delete</v-icon>
              </v-btn>
              <v-btn
                v-if="bulkDel"
                color="white"
                depressed
                class="text-capitalize"
                @click="bulkDel = false"
                ><v-icon color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <v-skeleton-loader type="table-tbody" v-if="loading"></v-skeleton-loader>
      <div class="mt-5 familiy" v-if="!loading && user">
        <v-data-table
          :headers="
            route == 'scholar' && role !== 'ADMIN' ? headersScholar : headers
          "
          :items="user.data"
          :page.sync="page"
          :items-per-page="perPage"
          class="elevation-0"
          hide-default-footer
          :show-select="bulkDel"
          :single-select="false"
          v-model="tempId"
        >
          <template v-slot:[`item.no`]="{ item }">
            {{
              user.data
                .map((x) => {
                  return x.id;
                })
                .indexOf(item.id) +
              1 +
              (page - 1) * perPage
            }}
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <div v-if="item.role == 'TF' || item.role == 'ADMIN_ASSESSMENT'">
              {{ label }}
            </div>
            <div v-else>
              {{ label }}
            </div>
          </template>
          <template v-slot:[`item.report`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    depressed
                    color="green"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      toViewReport(
                        item.relationships[0].scholar.id,
                        item.relationships[0].scholar.jenis_kelamin
                      )
                    "
                  >
                    <v-icon dark small> mdi-text-box-check </v-icon>
                  </v-btn>
                </template>
                <span>View Report</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.recap`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    depressed
                    color="green"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :to="`/recap/${item.relationships[0].scholar.id}`"
                  >
                    <v-icon dark small>
                      mdi-chart-timeline-variant-shimmer
                    </v-icon>
                  </v-btn>
                </template>
                <span>View Recap</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <!-- role !== 'SUPERADMIN' -->
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="role !== 'SUPERADMIN' && access"
                    :disabled="access[$route.params.role].length == 1"
                    fab
                    small
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    :to="`/update/user/${item.id}/${item.role.toLowerCase()}`"
                  >
                    <v-icon dark small> mdi-cog </v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    fab
                    small
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    :to="`/update/user/${item.id}/${item.role.toLowerCase()}`"
                  >
                    <v-icon dark small> mdi-cog </v-icon>
                  </v-btn>
                </template>
                <span>Manage User</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex">
              <v-btn
                small
                rounded
                depressed
                color="blue lighten-5"
                v-if="item.status == 1"
              >
                <p class="ma-0 blue--text">active</p>
              </v-btn>
              <v-btn
                small
                rounded
                depressed
                color="red lighten-5"
                v-if="item.status == 0"
              >
                <p class="ma-0 red--text">not active</p>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <div class="text-center pa-3" v-if="user">
      <v-pagination
        v-model="page"
        color="green"
        @input="getItem"
        :length="user.meta.last_page"
      ></v-pagination>
    </div>
    <div class="pa-1">
      <SelectPeriode
        v-bind:dialogPeriodeView="dialogPeriodeView"
        @close="closeDialog(1)"
        v-bind:gender="gender"
        v-bind:idScholar="idScholar"
      />
      <selectColumnScholar
        v-bind:dialogColumn="dialogColumn"
        v-bind:listColumn="displayedColumn"
        v-bind:from="columnFrom"
        @pick="changeColumn"
        @close="closeDialog(2)"
        ref="column"
      />
      <ExportScholar
        v-bind:dialogExport="dialogExport"
        v-bind:column="headersExport"
        @close="closeDialog(3)"
        @showColumn="selectColumns('child')"
        @reset="resetColumn()"
      />
      <ExportAdmin
        v-bind:dialogExportAdmin="dialogExportAdmin"
        v-bind:role="exportRole"
        @close="closeDialog(4)"
      />
      <DeleteBulk
        v-bind:dialogDelete="dialogDelete"
        v-bind:dataItem="placeId"
        @after="afterDelete"
        @close="closeDialog(5)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SelectPeriode from "../../../components/Report/view/Modal/selectPeriode.vue";
import ExportAdmin from "../../../components/UserManagement/Export/exportAdmin.vue";
import ExportScholar from "../../../components/UserManagement/Export/exportScholar.vue";
import SelectColumnScholar from "../../../components/UserManagement/List/selectColumnScholar.vue";
import DeleteBulk from "../Delete/deleteUserBulk.vue";

export default {
  components: {
    SelectPeriode,
    SelectColumnScholar,
    ExportScholar,
    ExportAdmin,
    DeleteBulk,
  },
  name: "ListUser",
  computed: {
    ...mapState({
      user: (state) => state.user.list_user,
      access: (state) => state.user.my_access,
      role: (state) => state.role,
    }),
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = 2019; i <= year; i++) {
        data.push({
          state: i,
          abbr: i,
        });
      }
      data.push({
        state: "All years",
        abbr: "",
      });
      return data;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.route = this.$route.params.role;
      switch (this.route) {
        case "data_control":
          this.label = "Data Control";
          this.include = "dataControl";
          break;
        case "tf":
          this.label = "TF Admin";
          this.include = "tf";
          break;
        case "admin_assessment":
          this.label = "Assessment Admin ";
          this.include = "adminAssessment";
          break;
        case "scholar":
          this.label = "Scholar";
          this.include = "scholar";
          break;
      }
      this.getItem();
    },
  },
  data() {
    return {
      find: "",
      status: 1,
      route: null,
      dataItem: null,
      loading: false,
      dialogExport: false,
      label: "",
      include: "",
      items: [
        { state: "Active User", abbr: 1 },
        { state: "Inactive User", abbr: 0 },
        { state: "All Users", abbr: "" },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "NO",
          align: "start",
          sortable: false,
          value: "no",
        },
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "nama_lengkap",
        },
        { text: "EMAIL", align: "start", sortable: false, value: "email" },
        { text: "STATUS", sortable: false, value: "status" },
        { text: "ROLE", sortable: false, value: "role" },
        { text: "ACTION", sortable: false, value: "actions" },
      ],
      headersExport: [
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "nama_lengkap",
          cols: "nama_lengkap",
        },

        {
          text: "EMAIL",
          align: "start",
          sortable: false,
          value: "email",
          cols: "email",
        },
        {
          text: "UNIVERSITY",
          align: "start",
          sortable: false,
          value: "relationships[0].scholar.universitas",
          cols: "universitas",
        },
        { text: "STATUS", sortable: false, value: "status", cols: "status" },
      ],
      headersScholar: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          cols: "id",
        },
        {
          text: "NO",
          align: "start",
          sortable: false,
          value: "no",
        },
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "nama_lengkap",
          cols: "nama_lengkap",
        },
        {
          text: "EMAIL",
          align: "start",
          sortable: false,
          value: "email",
          cols: "email",
        },
        {
          text: "UNIVERSITY",
          align: "start",
          sortable: false,
          value: "relationships[0].scholar.universitas",
          cols: "universitas",
        },
        { text: "STATUS", sortable: false, value: "status", cols: "status" },
        { text: "ROLE", sortable: false, value: "role", cols: "role" },
        { text: "REPORT", sortable: false, value: "report", cols: "report" },
        { text: "RECAP", sortable: false, value: "recap", cols: "recap" },
        { text: "ACTION", sortable: false, value: "actions", cols: "action" },
      ],
      page: 1,
      perPage: 10,
      idScholar: null,
      filterYear: null,
      gender: null,
      dialogPeriodeView: false,
      listColumn: [],
      dialogColumn: false,
      dialogExportAdmin: false,
      exportRole: "",
      columnFrom: "parent",
      columnExport: [],
      displayedColumn: [],
      bulkDel: false,
      tempId: [],
      dialogDelete: false,
      placeId: null,
    };
  },
  mounted() {
    this.route = this.$route.params.role;
    switch (this.route) {
      case "data_control":
        this.label = "Data Control";
        this.include = "dataControl";
        this.exportRole = "data-control";
        break;
      case "tf":
        this.label = "Admin TF";
        this.include = "tf";
        break;
      case "admin_assessment":
        this.label = "Admin Assessment";
        this.include = "adminAssessment";
        this.exportRole = "admin-assessment";
        break;
      case "scholar":
        this.label = "Scholar";
        this.include = "scholar";
        break;
    }
    this.getItem();
    this.getColumns();
  },
  methods: {
    deleteBulk() {
      let ids = this.tempId.map((el) => {
        return el.id;
      });
      this.placeId = ids;
      this.dialogDelete = true;
    },
    afterDelete() {
      this.getItem();
      this.closeDialog(5);
      this.bulkDel = false;
    },
    toViewReport(id, gender) {
      if (gender == "Laki-laki") {
        this.gender = "l";
      } else if (gender == "Perempuan") {
        this.gender = "p";
      } else {
        this.gender = "gay";
      }
      this.idScholar = id;
      this.dialogPeriodeView = true;
    },

    showDialogExportAdmin() {
      this.dialogExportAdmin = true;
      this.route = this.$route.params.role;
      switch (this.route) {
        case "data_control":
          this.exportRole = "data-control";
          break;
        case "tf":
          this.label = "Admin TF";
          this.include = "tf";
          this.exportRole = "admin-tf";
          break;
        case "admin_assessment":
          this.exportRole = "admin-assessment";
          break;
      }
    },

    getItem() {
      this.loading = true;
      let data = {
        role: this.route,
        include: this.include,
        find: this.find,
        status: this.status,
        year: this.filterYear,
        page: this.page,
        limit: this.perPage,
      };
      this.$store.commit("user/MUTATE_YEAR", this.filterYear);
      this.$store.commit("user/MUTATE_PHASE", this.status);
      this.$store.dispatch("user/listUser", data).then(() => {
        this.loading = false;
      });
    },
    dialogUpdateColumn() {
      this.dialogExport = true;
    },
    selectColumns(from) {
      this.columnFrom = from;
      this.dialogColumn = true;
      this.displayedColumn =
        from == "parent" ? this.listColumn : this.columnExport;
    },
    getColumns() {
      let optionHeader = [...this.headersScholar];
      this.$store.dispatch("getColumn", "scholar").then((data) => {
        data.forEach((k) => {
          if (
            k.request !== "id" &&
            k.request !== "universitas" &&
            k.request !== "nama_lengkap" &&
            k.request !== "email" &&
            k.request !== "role" &&
            k.request !== "status" &&
            k.request !== "recap" &&
            k.request !== "action" &&
            k.request !== "report"
          ) {
            optionHeader.push({
              text: k.view,
              sortable: false,
              value: `relationships[0].scholar.${k.request}`,
              cols: k.request,
            });
          }
        });
        // buat filter kolom di web
        optionHeader.forEach((l) => {
          if (l.cols !== "alamat" && l.cols !== "password_no_hash") {
            this.listColumn.push(l);
          }
        });
        let exceptColumn = ["id", "role", "report", "recap", "action"];
        this.columnExport = optionHeader.filter(
          ({ cols: a }) => !exceptColumn.some((b) => a === b)
        );
        // let pwd = "password_no_hash";
        // this.columnExport.push({
        //   text: pwd.replace(/_/g, " ").toUpperCase(),
        //   sortable: false,
        //   value: `relationships[0].scholar.${pwd}`,
        //   cols: pwd,
        // });
      });
    },
    changeColumn(column, from) {
      let picked = [];
      if (from == "parent") {
        this.listColumn.forEach((act) => {
          column.forEach((mutate) => {
            if (act.value == mutate.value) {
              picked.push(act);
            }
          });
        });
        this.headersScholar = picked;
      } else {
        this.columnExport.forEach((act) => {
          column.forEach((mutate) => {
            if (act.value == mutate.value) {
              picked.push(act);
            }
          });
        });
        this.headersExport = picked;
      }
      this.closeDialog(2);
    },
    resetColumn() {
      this.headersExport = [
        {
          text: "FULL NAME",
          align: "start",
          sortable: false,
          value: "nama_lengkap",
          cols: "nama_lengkap",
        },

        {
          text: "EMAIL",
          align: "start",
          sortable: false,
          value: "email",
          cols: "email",
        },
        {
          text: "UNIVERSITY",
          align: "start",
          sortable: false,
          value: "relationships[0].scholar.universitas",
          cols: "universitas",
        },
        { text: "STATUS", sortable: false, value: "status", cols: "status" },
      ];
      this.columnFrom = "child";
      this.$refs.column.resetCol();
    },
    closeDialog(q) {
      switch (q) {
        case 1:
          this.dialogPeriodeView = false;
          break;
        case 2:
          this.dialogColumn = false;
          break;
        case 3:
          this.dialogExport = false;
          break;
        case 4:
          this.dialogExportAdmin = false;
          break;
        case 5:
          this.dialogDelete = false;
          break;
      }
    },
  },
};
</script>

<style>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
.familiy {
  font-family: "Roboto", sans-serif !important;
}
</style>
