<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogPeriodeView"
      scrollable
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
      max-width="530px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Select a report to view</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2" v-if="gender">
          <v-container>
            <div class="d-flex justify-space-between" >
              <v-hover v-slot="{ hover }">
                <v-card
                  width="150px"
                  height="150px"
                  :color="hover ? 'green lighten-5' : 'white'"
                  :to="`/report/baseline/${idScholar}`"
                  class="d-flex align-center justify-center flex-column pa-3"
                >
                  <img
                    :src="
                      require(`../../../../assets/img/ava-baseline-${gender}.png`)
                    "
                    height="90px"
                    class="mb-3"
                    alt="ava"
                  />
                  Baseline</v-card
                >
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-card
                  width="150px"
                  height="150px"
                  :color="hover ? 'green lighten-5' : 'white'"
                  :to="`/report/midline/${idScholar}`"
                  class="d-flex align-center justify-center flex-column pa-3"
                >
                  <img
                    :src="
                      require(`../../../../assets/img/ava-midline-${gender}.png`)
                    "
                    height="90px"
                    class="mb-3"
                    alt="ava"
                  />

                  Midline</v-card
                >
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-card
                  height="150px"
                  width="150px"
                  :color="hover ? 'green lighten-5' : 'white'"
                  :to="`/report/endline/${idScholar}`"
                  class="d-flex align-center justify-center flex-column pa-3"
                >
                  <img
                    :src="
                      require(`../../../../assets/img/ava-endline-${gender}.png`)
                    "
                    height="90px"
                    class="mb-3"
                    alt="ava"
                  />
                  Endline</v-card
                >
              </v-hover>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "dialogPeriodeView",

  props: ["dialogPeriodeView", "idScholar", "gender"],

  methods: {},
};
</script>
