<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogExportAdmin"
      @click:outside="$emit('close')"
      scrollable
      max-width="559px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Select date to export data</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <div>
              <v-row>
                <v-col cols="3"> Status </v-col>
                <v-col cols="6">
                  <!-- {{ status ? status : "All Scholars" }} -->
                  <v-select
                    dense
                    hide-details
                    :items="items"
                    item-text="state"
                    item-value="abbr"
                    v-model="status"
                    label="Filter Status"
                    solo
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-tooltip top color="info" max-width="200px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        depressed
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        color="blue"
                        dark
                        ><v-icon small>mdi-help</v-icon></v-btn
                      >
                    </template>
                    <span
                      >Filter
                      {{
                        role
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())
                      }}</span
                    >
                  </v-tooltip>
                </v-col>
              </v-row>
              <div class="d-flex justify-center mt-5">
                <template>
                  <v-btn
                    @click="exportToExcel"
                    color="green"
                    dark
                    v-if="!loading"
                    ><v-icon>mdi-file-export</v-icon> Export Data</v-btn
                  >
                  <v-btn color="green" dark v-if="loading">
                    Generating...</v-btn
                  >
                </template>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "adminExport",

  props: ["dialogExportAdmin", "role"],
  data() {
    return {
      calendar1: false,
      dateRangeTxt: null,
      status: 2,
      loading: false,
      dateTime: "",
      terambil: null,
      items: [
        { state: "Active User", abbr: 1 },
        { state: "Inactive User", abbr: 0 },
        { state: "All Users", abbr: 2 },
      ],
    };
  },

  methods: {
    saveDate(time) {
      this.$refs.menu.save(time);
      this.dateRangeTxt = `${this.$date(time[0]).format(
        "DD MMMM YYYY"
      )} - ${this.$date(time[1]).format("DD MMMM YYYY")}`;
      this.dateTime = [
        `${this.$date(time[0]).format("YYYY-MM-DD")} 00:01:00`,
        `${this.$date(time[1]).format("YYYY-MM-DD")} 23:59:00`,
      ];
    },
    exportToExcel() {
      this.loading = true;

      let status = {
        status: this.status,
      };
      let data = {
        role: this.role,
        status: status,
      };
      this.$store.dispatch("exports/exportAdmin", data).then((data) => {
        this.loading = false;
        location.href = data.data.path;
        this.$emit("close");
      });
    },
  },
};
</script>
<style scoped>
.no_decoration {
  text-decoration: none;
}
</style>
